import { ColumnsType } from 'antd/es/table';
import { IDescriptive } from '../../data/descriptive';
import DataTable from './DataTable';
import Plot from 'react-plotly.js';
import { useEffect, useState, useRef } from 'react';
import { GetDescriptiveInfo } from '../../services/vnexPlotApi';
import { useProfile } from '../../contexts/ProfileContext';
import Plotly from 'plotly.js-basic-dist';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';

interface DescriptiveProps {
  projectName: string;
}
const Descriptive: React.FC<DescriptiveProps> = ({ projectName }) => {
  // Columns definition
  const columns: ColumnsType<IDescriptive> = [
    {
      title: 'Sr. No.',
      dataIndex: 'key',
      key: 'key',
      sorter: (a, b) => a.key - b.key,
      width: '10%',
    },
    {
      title: 'Sample Name',
      dataIndex: 'sampleName',
      key: 'sampleName',
      sorter: (a, b) => a.sampleName.localeCompare(b.sampleName),
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      sorter: (a, b) => a.group.localeCompare(b.group),
      render: (text: string) => (
        <span
          style={{
            color: text === 'U' ? '#217867' : '#343A40',
            fontWeight: 'bold',
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Batch',
      dataIndex: 'batch',
      key: 'batch',
      sorter: (a, b) => a.batch.localeCompare(b.batch),
    },
    {
      title: 'Outlier',
      dataIndex: 'outlier',
      key: 'outlier',
      sorter: (a, b) => a.outlier.localeCompare(b.outlier),
    },
  ];

  const [data, setData] = useState<IDescriptive[]>([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<IDescriptive[]>([]);
  const { profile } = useProfile();
  const groupCounts = data.reduce<Record<string, number>>((acc, item) => {
    acc[item.group] = (acc[item.group] || 0) + 1;
    return acc;
  }, {});
  const plotRef = useRef<any>(null);
  const groups = Object.keys(groupCounts); // ['U', 'P']
  const counts = Object.values(groupCounts); // [30, 24]
  const colors = ['#217867', '#343A40']; // Colors for each group

  useEffect(() => {
    const fetchData = async () => {
      if (profile) {
        try {
          setLoading(true);
          const response = await GetDescriptiveInfo(projectName, profile.email);

          const formattedData: IDescriptive[] = response.data.map(
            (item: any, index: number) => ({
              key: index,
              sampleName: item.sample_intern,
              group: item.group,
              batch: item.Batch || '',
              outlier: item.Outlier || '',
            }),
          );
          setData(formattedData);
          setFilteredData(formattedData);
          setLoading(false);
        } catch (error: any) {
          setData([]);
          setFilteredData([]);
          console.error('Error fetching meta sample info:', error);
          setLoading(false);
          const errorMessage =
            error?.response?.data?.detail ||
            'An error occurred while fetching data.';
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    };

    projectName && fetchData();
  }, [profile]);
  // Handle search functionality
  const handleSearch = (searchTerm: string) => {
    if (!searchTerm) {
      // If no search term, reset to original data
      setFilteredData(data);
    } else {
      const lowerCaseTerm = searchTerm.toLowerCase();

      const filtered = data.filter((item) =>
        Object.values(item).some(
          (value) => String(value).toLowerCase().includes(lowerCaseTerm), // Convert both value and searchTerm to lowercase
        ),
      );

      setFilteredData(filtered);
      console.log('Filtered Data:', filtered); // Debugging line
    }

    console.log('Search Term:', searchTerm); // Debugging line
  };

  const downloadTableData = (
    data: any[] | undefined,
    format: string | undefined,
  ) => {
    if (!data || data.length === 0) {
      console.error('No data available to download');
      return;
    }

    if (format === 'csv') {
      // Generate CSV content
      const headers = Object.keys(data[0]);
      const csvContent = [
        ['Sr. No.', 'Sample Name', 'Group', 'Batch', 'Outlier'],
        // headers, // Add headers
        ...data.map((row: any) => headers.map((header) => row[header])),
      ]
        .map((e) => e.join(',')) // Convert each row to a comma-separated string
        .join('\n'); // Join all rows with a newline

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'data_table.csv');
      link.click();
    } else if (format === 'json') {
      // Generate JSON content
      const jsonContent = JSON.stringify(data, null, 2); // Pretty print JSON
      const blob = new Blob([jsonContent], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'data_table.json');
      link.click();
    } else {
      console.error('Unsupported format:', format);
    }
  };

  const DownloadImage = async () => {
    try {
      if (plotRef.current) {
        console.log(plotRef.current);
        const plotNode = plotRef.current.querySelector('.js-plotly-plot');
        const dataUrl = await Plotly.toImage(plotNode, {
          format: 'svg', // You can use 'png' or 'jpeg' if preferred
          height: 400,
          width: 600,
        });

        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'Graph.svg';
        link.click();
        toast.success('Image downloaded successfully!', {
          position: 'top-right',
        });
      } else {
        console.error('Plot reference is not available.');
      }
    } catch (error) {
      console.error('Error downloading image:', error);
      toast.error('Failed to download image.', {
        position: 'top-right',
      });
    }
    console.log('plotRef Clicked descriptive', plotRef);
  };

  return (
    <div>
      <h1 className="text-2xl font-semibold mb-5">Data Descriptives</h1>
      <div className="flex justify-between ">
        <p>Number of samples inside each group of interest</p>
        <button
          className="text-nexco-green mr-8"
          onClick={() => DownloadImage()}
        >
          Download
        </button>
      </div>

      <div className="ml-5 my-20">
        <h3 className="text-xl font-semibold mb-5">Sample distribution</h3>
        <p>Number of samples inside each group of interest</p>

        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <ClipLoader color="#36d7b7" size={50} /> {/* Loader component */}
          </div>
        ) : (
          <div className="my-10 ml-20" ref={plotRef}>
            <Plot
              data={groups.map((group, index) => ({
                x: [group],
                y: [counts[index]],
                type: 'bar',
                marker: {
                  color: colors[index],
                },
                name: group,
              }))}
              layout={{
                // title: 'Number of Samples by Group',
                xaxis: { title: 'Group' },
                yaxis: { title: 'Number of Samples' },
                showlegend: true,
                margin: { t: 40, l: 60, r: 20, b: 40 },
                height: 400,
                width: 600,
              }}
              config={{
                responsive: true,
                displayModeBar: false,
                displaylogo: false,
              }}
            />
            <ToastContainer />
          </div>
        )}
      </div>
      <h3 className="text-xl font-semibold mb-5">Sample metadata</h3>
      <p>Sample definitions</p>

      <DataTable
        columns={columns}
        data={filteredData}
        onSearch={handleSearch}
        downloadTableData={downloadTableData}
      />
    </div>
  );
};

export default Descriptive;
