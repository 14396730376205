import { Checkbox, Select, Divider, Typography, Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line
import Plot from 'react-plotly.js';
import { useProfile } from '../../contexts/ProfileContext';
import { Fullscreen } from 'lucide-react';
import { Clustring, ProjectGroups } from '../../services/vnexPlotApi';
import { toast, ToastContainer } from 'react-toastify';
import Plotly, { downloadImage } from 'plotly.js-basic-dist';
import { ClipLoader } from 'react-spinners';
const { Option } = Select;
const { Title } = Typography;
import 'react-toastify/dist/ReactToastify.css';

interface ClusteringProps {
  projectName: string;
}
export interface IfilterData {
  plot: string;
  dimensions: string;
  num_genes: string;
  row_metric: string;
  col_metric: string;
  selectedGroups: string[];
}

const Clustering: React.FC<ClusteringProps> = ({ projectName }) => {
  const [groups, setGroups] = useState<string[]>([]);
  const { profile } = useProfile();
  const plotRef = useRef<any>();

  const email = profile ? profile.email : '';

  const initialFilterData = {
    plot: 'PCA',
    dimensions: '3',
    num_genes: '250',
    row_metric: 'pearson',
    col_metric: 'euclidean',
    selectedGroups: groups,
  };

  const [filterData, setFilterData] = useState<IfilterData>(initialFilterData);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();

  const handleChange = (value: string | string[], name: string) => {
    if (typeof (value === 'string' || value === 'object') && name) {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const clusteringRequest = async () => {
      try {
        setLoading(true);
        const response = await Clustring(filterData, email, projectName);
        setData(response.data);
        // console.log("data", response)
        setLoading(false);
      } catch (error: any) {
        setLoading(false);

        const errorMessage =
          error?.response?.data?.detail ||
          'An error occurred while fetching data.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };

    if (filterData.selectedGroups.length > 0) {
      clusteringRequest();
    }
  }, [filterData]);

  useEffect(() => {
    const GroupsData = async () => {
      try {
        setLoading(true);
        const response = await ProjectGroups(projectName, email);

        setGroups(response.data);
        setFilterData((prev) => ({
          ...prev,
          ['selectedGroups']: response.data,
        }));
        setLoading(false);
      } catch (error: any) {
        setLoading(false);

        const errorMessage =
          error?.response?.data?.detail ||
          'An error occurred while fetching data.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };
    GroupsData();
  }, []);

  const DownloadImage = async () => {
    const modebarButton = plotRef.current.querySelector(
      '.modebar-btn[data-title="Download plot as a png"]',
    );
    if (modebarButton) {
      modebarButton.click();
      toast.success('Image downloaded successfully!', {
        position: 'top-right',
      });
    } else {
      const errorMessage = 'Plot reference is not available.';
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const dimension3XaxisData = data?.coordinates?.map((item: any) => item?.PC1);
  const dimension3YaxisData = data?.coordinates?.map((item: any) => item?.PC2);
  const dimension3ZaxisData = data?.coordinates?.map((item: any) => item?.PC3);

  const colors = data?.coordinates?.map((item: any) => item?.group_color);

  const renderPlot = () => {
    if (filterData.plot === 'PCA') {
      if (filterData.dimensions === '3') {
        return (
          <div ref={plotRef}>
            <Plot
              data={[
                {
                  x: dimension3XaxisData,
                  y: dimension3YaxisData,
                  z: dimension3ZaxisData,
                  type: 'scatter3d',
                  mode: 'markers',
                  marker: { color: colors },
                },
              ]}
              layout={{
                title: '3D PCA Plot',
                width: 1000,
                height: 900,
                showlegend: true,
              }}
              config={{
                responsive: true,
                displayModeBar: true,
                modeBarButtonsToRemove: [
                  'zoom3d',
                  'pan3d',
                  'select2d',
                  'lasso2d',
                  'zoomIn2d',
                  'zoomOut2d',
                  'autoScale2d',
                  'resetScale2d',
                  'hoverClosestCartesian',
                  'hoverCompareCartesian',
                  'toggleSpikelines',
                  'resetCameraDefault3d',
                  'resetCameraLastSave3d',
                  'hoverClosest3d',
                  'orbitRotation',
                  'tableRotation',
                ],
                displaylogo: false,
                showTips: false,
              }}
            />
          </div>
        );
      } else if (filterData.dimensions === '2') {
        return (
          <div ref={plotRef}>
            <Plot
              data={[
                {
                  x: dimension3XaxisData,
                  y: dimension3YaxisData,
                  //z: [3, 4, 5],
                  type: 'scatter',
                  mode: 'markers',
                  marker: { color: colors, size: 15 },
                },
              ]}
              layout={{
                title: '2D PCA Plot',
                height: 600,
                width: 1000,
                showlegend: true,
              }}
              config={{
                responsive: true,
                displayModeBar: true,
                modeBarButtonsToRemove: [
                  'zoom2d',
                  'pan2d',
                  'select2d',
                  'lasso2d',
                  'zoomIn2d',
                  'zoomOut2d',
                  'autoScale2d',
                  'resetScale2d',
                  'hoverClosestCartesian',
                  'hoverCompareCartesian',
                  'toggleSpikelines',
                ],
                displaylogo: false,
              }}
            />
          </div>
        );
      }
    } else if (data.data && filterData.plot === 'Heatmap') {
      return (
        <div ref={plotRef}>
          <Plot
            data={[
              {
                z: data.data,
                type: 'heatmap',
                colorscale: 'Viridis',
              },
            ]}
            layout={{
              title: 'Heatmap',
              height: 600,
              width: 1000,
              showlegend: true,
            }}
            config={{
              responsive: true,
              displayModeBar: true,
              modeBarButtonsToRemove: [
                'zoom2d',
                'pan2d',
                'select2d',
                'lasso2d',
                'zoomIn2d',
                'zoomOut2d',
                'autoScale2d',
                'resetScale2d',
                'hoverClosestCartesian',
                'hoverCompareCartesian',
                'toggleSpikelines',
              ],
              displaylogo: false,
            }}
          />
        </div>
      );
    } else {
      return <h1>Plot type not recognized</h1>;
    }
  };

  return (
    <div className="flex justify-between">
      <div
        className="border-r-2 border-dashed w-1/4"
        style={{ padding: '16px' }}
      >
        {/* Filter Section */}
        <Title level={5}>Filter</Title>

        <Form.Item label="Select groups (at least two):">
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <ClipLoader color="#36d7b7" size={50} /> {/* Loader component */}
            </div>
          ) : (
            <Checkbox.Group
              value={filterData.selectedGroups} // This binds to the state
              onChange={(checkedValues) =>
                handleChange(checkedValues, 'selectedGroups')
              }
            >
              {groups.map((group) => (
                <Checkbox key={group} value={group}>
                  {group}
                </Checkbox>
              ))}
            </Checkbox.Group>
          )}
        </Form.Item>

        <Divider />

        {/* Figure Section */}
        <Title level={5}>Figure</Title>
        <Form.Item label="Plot" colon={false}>
          <Select
            defaultValue={filterData.plot}
            value={filterData.plot}
            style={{ width: '100%' }}
            //onChange={handleChange}
            onChange={(value) => handleChange(value, 'plot')} // Pass value and name
          >
            <Option value="PCA">PCA</Option>
            <Option value="Heatmap">Heatmap</Option>
          </Select>
        </Form.Item>

        <Divider />

        {/* Parameters Section */}
        <Title level={5}>Parameters</Title>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {/* PCA Parameters */}

          {filterData.plot === 'PCA' ? (
            <div>
              <Title level={5}>PCA</Title>
              <Form.Item
                label="Number of dimensions"
                colon={false}
                name="dimensions"
                style={{ marginBottom: 0 }}
              >
                <Select
                  defaultValue={filterData.dimensions}
                  value={filterData.dimensions}
                  style={{ width: '100%' }}
                  onChange={(value) => handleChange(value, 'dimensions')} // Pass value and name
                >
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                </Select>
              </Form.Item>
            </div>
          ) : (
            <div>
              {' '}
              <Title level={5}>Heatmap</Title>
              <Form.Item
                label="Number of genes"
                colon={false}
                name="genes"
                style={{ marginBottom: 0 }}
              >
                <Select
                  defaultValue="250"
                  style={{ width: '100%' }}
                  onChange={(value) => handleChange(value, 'num_genes')}
                >
                  <Option value="250">250</Option>
                  <Option value="500">500</Option>
                  <Option value="1000">1000</Option>
                  <Option value="2000">2000</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Distance metric for rows"
                colon={false}
                name="rowMetric"
                style={{ marginBottom: 0, gap: '20px' }}
              >
                <Select
                  defaultValue="pearson"
                  style={{ width: '100%' }}
                  onChange={(value) => handleChange(value, 'row_metric')}
                >
                  <Option value="spearman">spearman</Option>
                  <Option value="pearson">pearson</Option>
                  <Option value="euclidean">euclidean</Option>
                  <Option value="kendall">kendall</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Distance metric for columns"
                colon={false}
                name="colMetric"
                style={{ marginBottom: 0 }}
              >
                <Select
                  defaultValue="euclidean"
                  style={{ width: '100%' }}
                  onChange={(value) => handleChange(value, 'col_metric')}
                >
                  <Option value="spearman">spearman</Option>
                  <Option value="pearson">pearson</Option>
                  <Option value="euclidean">euclidean</Option>
                  <Option value="kendall">kendall</Option>
                </Select>
              </Form.Item>
            </div>
          )}
        </div>
      </div>

      <div className="w-3/4 pl-20">
        <div className="flex justify-between ">
          <h1 className="text-2xl font-semibold mb-5">Clustering</h1>

          <button
            className="text-nexco-green mr-8"
            onClick={() => DownloadImage()}
          >
            Download
          </button>
        </div>
        <ToastContainer />
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <ClipLoader color="#36d7b7" size={50} /> {/* Loader component */}
          </div>
        ) : (
          <div className="mt-6 overflow-hidden mr-10">{renderPlot()}</div>
        )}
      </div>
    </div>
  );
};

export default Clustering;
