import React, { useEffect, useRef } from 'react';
import { verifyOtp } from '../../services/loginApi';
import Swal from 'sweetalert2';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

interface OTPFormProps {
  otp: string[];
  setOtp: (otp: string[]) => void;
  handleBackToEmail: () => void;
  handleResendOtp: () => void;
  email: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setMessage: (message: string | null) => void;
  setMessageType: (type: 'error' | 'success' | null) => void;
}

const OTPForm: React.FC<OTPFormProps> = ({
  otp,
  setOtp,
  handleBackToEmail,
  handleResendOtp,
  email,
  loading,
  setLoading,
  setMessage,
  setMessageType,
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleOtpChange = (index: number, value: string) => {
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('Text');
    if (/^\d{6}$/.test(pasteData)) {
      const newOtp = pasteData.split('');
      setOtp(newOtp);
      newOtp.forEach((value, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index]!.value = value;
        }
      });
      inputRefs.current[5]?.focus();
    }
    e.preventDefault();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const otpValue = otp.join('');
      const response = await verifyOtp(email, otpValue); // keep otpValue as string
      setLoading(false);
      setMessage('OTP verified successfully.');
      setMessageType('success');
      login();
      navigate('/');
    } catch (error) {
      setLoading(false);
      setMessage('Failed to verify OTP. Please try again.');
      setMessageType('error');
    }
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);
  return (
    <div>
      <div className="flex flex-col items-center mt-6">
        <h2 className="text-3xl font-bold text-gray-700">
          Verify your Account
        </h2>
        <p className="mt-2 text-lg text-center text-neutral-400">
          Enter the 6-digit OTP sent to your email.
          <br /> Don't share it with anyone.
        </p>
      </div>
      <form className="mt-8" onSubmit={handleSubmit}>
        <div className="flex justify-center mt-4 space-x-2">
          {otp.map((value, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              className="w-12 h-12 text-lg text-center border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600"
              value={value}
              onChange={(e) => handleOtpChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={handlePaste}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
        <button
          type="submit"
          className="relative w-full px-5 py-3 mt-4 text-lg text-white bg-teal-700 rounded-lg hover:bg-teal-800 focus:outline-none focus:bg-teal-800"
          disabled={loading}
        >
          {loading ? (
            <svg
              className="w-5 h-5 mx-auto text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              ></path>
            </svg>
          ) : (
            'Submit'
          )}
        </button>
        <button
          type="button"
          onClick={handleBackToEmail}
          className="w-full px-5 py-3 mt-4 text-lg text-black bg-white border rounded-lg hover:bg-neutral-100 focus:outline-none focus:bg-neutral-100"
        >
          Change Email
        </button>
        <div className="mt-3 text-center">
          <p className="text-lg text-neutral-400">
            Didn’t receive a code?
            <span
              className="text-teal-600 cursor-pointer hover:underline"
              onClick={handleResendOtp}
            >
              Resend OTP
            </span>
          </p>
        </div>
      </form>
    </div>
  );
};

export default OTPForm;
