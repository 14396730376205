import { Form, Select, Typography } from 'antd';
const { Option } = Select;
const { Title } = Typography;
import React, { useEffect, useRef, useState } from 'react';
import { useProfile } from '../../../contexts/ProfileContext';
import Plot from 'react-plotly.js';
import { toast, ToastContainer } from 'react-toastify';
import {
  Comparison,
  GetDifferentialExpression,
} from '../../../services/vnexPlotApi';
import DataTable from '../DataTable';
import { IBasicAnalysis } from '../../../data/basicAnalysis';
import { ColumnsType } from 'antd/es/table';
import VolcanoPlot from './plots/VolcanoPlot';

interface DifferentialExpressionProps {
  projectName: string;
}
export interface IfilterDataDifferential {
  comparison: string[];
  fold_change_threshold: number | null;
  statistical_significance_threshold: number | null;
  use: string;
  plot: string;
  genes: string;
}

const DifferentialExpression: React.FC<DifferentialExpressionProps> = ({
  projectName,
}) => {
  const pageSize = 5;

  const [comparison, setComparison] = useState<string[]>([]);
  const { profile } = useProfile();
  const email = profile ? profile.email : '';
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState<any[]>([]); // State for search results
  const [data, setData] = useState<any>();
  const plotRef = useRef<any>();
  const initialFilterData = {
    comparison: comparison,
    fold_change_threshold: 2.0,
    statistical_significance_threshold: 0.05,
    use: 'Pvalue',
    plot: 'Volcano',
    genes: 'All genes',
  }

  useState<IfilterDataDifferential>(initialFilterData);


  const [filterData, setFilterData] = useState<IfilterDataDifferential>(initialFilterData);

  interface CompData {
    ensembl_gene_id: string[];
    entrezgene_id: string[];
    hgnc_symbol: string[];
    description: string[];
    'mean.G1': number[];
    'mean.G2': number[];
    foldChange: number[];
    Pval: number[];
    FDR: number[];
    diffExp: string[];
  }

  const [compData, setCompData] = useState<CompData | null>(null);

  const handleChange = (
    value: string | number | string[] | null,
    name: string,
  ) => {
    if (
      (typeof value === 'string' ||
        Array.isArray(value) ||
        typeof value === 'number' ||
        value === null) &&
      name
    ) {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    const GroupsData = async () => {
      try {
        setLoading(true);
        const response = await Comparison(projectName, email);
        const fetchedData = response.data || [];
        setComparison(fetchedData);
        setFilterData((prev) => ({
          ...prev,
          comparison: fetchedData.length ? fetchedData[0] : '',
        }));
        setLoading(false);
      } catch (error: any) {
        setLoading(false);

        const errorMessage =
          error?.response?.data?.detail ||
          'An error occurred while fetching data.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };
    GroupsData();
  }, []);

  useEffect(() => {
    const DifferentialExpressionRequest = async () => {
      try {
        setLoading(true);
        const response = await GetDifferentialExpression(
          filterData,
          email,
          projectName,
        );

        setData(response.data);
        console.log(response.data);
        setSearchResults(response.data?.filtered_data);
        setCompData(response.data?.comp_data);
        setLoading(false);
      } catch (error: any) {
        setData([]);
        setSearchResults([]);
        setLoading(false);
        const errorMessage =
          error?.response?.data?.detail ||
          'An error occurred while fetching data.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };
    if (filterData.comparison.length > 0) {
      DifferentialExpressionRequest();
    }
  }, [filterData]);

  const DifferentialExperienceFilteredData = data?.filtered_data || [];
  const DifferentialExperienceData = data?.rest || [];
  const meanGroup1Name =
    DifferentialExperienceFilteredData[0]?.mean[0]?.name || '';
  const meanGroup1Value = DifferentialExperienceFilteredData.map(
    (item: any) => item.mean[0]?.value,
  );

  const meanGroup2Name =
    DifferentialExperienceFilteredData[0]?.mean[1]?.name || '';
  // const meanGroup2Value = DifferentialExperienceFilteredData.map(
  //   (item: any) => item.mean[1]?.value,
  // );

  const DifferentialExperienceRestData = data?.rest || [];
  const DifferentialExperienceMaPlot =
    data?.ma_plot_data?.significant_genes || [];
  const DifferentialExperienceMaPlotRest = data?.ma_plot_data?.all_genes || [];
  const DifferentialExperiencePCA = data?.pca_data?.coordinates || [];
  const DifferentialExperienceHeatMap = data?.heatmap_data?.data || [];

  const columns: ColumnsType<IBasicAnalysis> = [
    {
      title: 'ensembl_gene_id',
      dataIndex: 'ensembl_gene_id',
      key: 'ensembl_gene_id',
      sorter: (a, b) => a.ensembl_gene_id.localeCompare(b.ensembl_gene_id),
    },
    {
      title: 'entrezgene_id',
      dataIndex: 'entrezgene_id',
      key: 'entrezgene_id',
      sorter: (a, b) => a.entrezgene_id - b.entrezgene_id,
      render: (text: number) => {
        return <span style={{ fontWeight: 'bold' }}>{text}</span>;
      },
    },
    {
      title: 'hgnc_symbol',
      dataIndex: 'hgnc_symbol',
      key: 'hgnc_symbol',
      sorter: (a, b) => a.hgnc_symbol.localeCompare(b.hgnc_symbol),
    },
    {
      title: 'description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: `mean_${meanGroup1Name}`,
      dataIndex: `mean`,
      key: `mean`,
      render: (meanArray: any) => {
        const meanItem = meanArray?.find(
          (item: any) => item.name === meanGroup1Name,
        );
        return meanItem ? meanItem.value : '';
      },
    },
    {
      title: `mean_${meanGroup2Name}`,
      dataIndex: `mean`,
      key: `mean`,
      render: (meanArray: any) => {
        const meanItem = meanArray?.find(
          (item: any) => item.name === meanGroup2Name,
        );
        return meanItem ? meanItem.value : '';
      },
    },
    {
      title: 'foldChange',
      dataIndex: 'foldChange',
      key: 'foldChange',
      sorter: (a, b) => a.foldChange - b.foldChange,
    },
    {
      title: 'Pval',
      dataIndex: 'Pval',
      key: 'Pval',
      sorter: (a, b) => a.Pval - b.Pval,
    },
    {
      title: 'FDR',
      dataIndex: 'FDR',
      key: 'FDR',
      sorter: (a, b) => a.FDR - b.FDR,
    },
    {
      title: 'diffExp',
      dataIndex: 'diffExp',
      key: 'diffExp',
      sorter: (a, b) => a.diffExp.localeCompare(b.diffExp),
    },
    {
      title: '_row',
      dataIndex: '_row',
      key: '_row',
      sorter: (a, b) => a._row.localeCompare(b._row),
    },
  ];

  // Add a small constant to avoid log(0) errors
  const epsilon = 1e-6;

  // Convert yPValueLog10 to logs (e.g., -log10)
  const yPValueLog10 = DifferentialExperienceFilteredData.map((item: any) =>
    item?.Pval ? -Math.log10(item.Pval + epsilon) : null,
  );
  // Convert xFoldChangeLog2 to logs (log2)
  const xFoldChangeLog2 = DifferentialExperienceFilteredData.map((item: any) =>
    item?.foldChange
      ? Math.log2(Math.abs(item.foldChange) + epsilon) *
        Math.sign(item.foldChange)
      : null,
  );
  const xFoldChangeLog2UpRegulated = xFoldChangeLog2.filter(
    (value: any) => value !== null && value > 0,
  );
  const xFoldChangeLog2DownRegulated = xFoldChangeLog2.filter(
    (value: any) => value !== null && value < 0,
  );

  // Convert pValueLogYRest to logs (e.g., -log10)
  const pValueLogYRest = DifferentialExperienceData.map((item: any) =>
    item?.Pval ? -Math.log10(item.Pval + epsilon) : null,
  );
  // Convert foldChangeXLogRest to logs (log2)
  const foldChangeXLogRest = DifferentialExperienceData.map(
    (item: any) =>
      item?.foldChange
        ? Math.log2(Math.abs(item.foldChange) + epsilon) *
          Math.sign(item.foldChange)
        : null,
  );

  // MA Plot Data

  ////////////
  ////
  ////

  const XAverageExpression = DifferentialExperienceMaPlot.map(
    (item: any) => item?.avg_expression,
  );
  const YLogFoldChange = DifferentialExperienceMaPlot.map(
    (item: any) => item?.log_fold_change,
  );
  const YLogFoldChangeUpRegulated = YLogFoldChange.filter(
    (value: any) => value !== null && value > 0,
  );

  const YLogFoldChangeDownRegulated = YLogFoldChange.filter(
    (value: any) => value !== null && value < 0,
  );

  const geneId = DifferentialExperienceMaPlot.map((item: any) => item?.gene_id);
  const ensembl_id = DifferentialExperienceMaPlot.map(
    (item: any) => item?.ensembl_id,
  );
  const XAverageExpressionRest = DifferentialExperienceMaPlotRest.map(
    (item: any) => item?.avg_expression,
  );
  const YLogFoldChangeRest = DifferentialExperienceMaPlotRest.map(
    (item: any) => item?.log_fold_change,
  );
  ////
  ////
  ////
  ////
  ////DotPlot
  ////
  ////
  ////

  const XMeanGroup1FilteredUpRegulated = meanGroup1Value.filter(
    (value: any) => value !== null && value > 0,
  );
  const XMeanGroup1FilteredDownRegulated = meanGroup1Value.filter(
    (value: any) => value !== null && value < 0,
  );
  const YMeanGroup2Filtered = DifferentialExperienceFilteredData.map(
    (item: any) => item.mean[1]?.value,
  );
  const XMeanGroup1Rest = DifferentialExperienceRestData.map(
    (item: any) => item.mean[0]?.value,
  );
  const YMeanGroup2Rest = DifferentialExperienceRestData.map(
    (item: any) => item.mean[1]?.value,
  );

  ////PCA Data
  ////
  ////
  ////

  const validateGroups = (coordinates: any) => {
    const groupMap = new Map();

    for (const { group, group_color } of coordinates) {
      if (groupMap.has(group)) {
        // Validate if group_color is consistent
        if (groupMap.get(group) !== group_color) {
          throw new Error(
            `Inconsistent group_color for group: ${group}. Expected: ${groupMap.get(
              group,
            )}, Found: ${group_color}`,
          );
        }
      } else {
        // Add new group to the map
        groupMap.set(group, group_color);
      }
    }

    return groupMap;
  };
  const groupMap = validateGroups(DifferentialExperiencePCA);
  const groupObject = Object.fromEntries(groupMap);
  const groupsName = Object.keys(groupObject); // ['Test1', 'Test3']
  const groupsColor = Object.values(groupObject);

  const XPCAMean = DifferentialExperiencePCA.map((item: any) => item?.PC1);
  const YPCAMean = DifferentialExperiencePCA.map((item: any) => item?.PC2);
  ////
  ////
  ////

  const handleSearch = (searchTerm: string) => {
    if (!searchTerm) {
      // Reset to original data if no search term
      setSearchResults(DifferentialExperienceFilteredData);
    } else {
      const lowerCaseTerm = searchTerm.toLowerCase();
      const filtered = DifferentialExperienceFilteredData.filter((item: any) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(lowerCaseTerm),
        ),
      );

      setSearchResults(filtered);
    }
  };

  const downloadTableData = (data: IBasicAnalysis[], format: string) => {
    if (format === 'csv') {
      const csvContent = [
        [
          'Serial No',
          'ensembl_gene_id',
          'entrezgene_id',
          'hgnc_symbol',
          'description',
          'mean_Test1',
          'vmean_Test3',
          'foldChange',
          'Pval',
          'FRD',
          'diffExp',
          '_row',
        ],
        ...data.map((row: any) => [
          row.key,
          row.ensembl_gene_id,
          row.entrezgene_id,
          row.hgnc_symbol,
          row.description,
          row[`mean_${meanGroup1Name}`],
          row[`mean_${meanGroup2Name}`],
          row.foldChange,
          row.Pval,
          row.FDR,
          row.diffExp,
          row._row,
        ]),
      ]
        .map((e) => e.join(','))
        .join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'data_table.csv');
      link.click();
    } else if (format === 'json') {
    }
  };

  const DownloadImage = async () => {
    const modebarButton = plotRef.current.querySelector(
      '.modebar-btn[data-title="Download plot as a png"]',
    );
    if (modebarButton) {
      modebarButton.click();
      toast.success('Image downloaded successfully!', {
        position: 'top-right',
      });
    } else {
      const errorMessage = 'Plot reference is not available.';
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const DifferentialExpressionPlots = () => {
    if (filterData.plot === 'Volcano') {
      if (compData) {
      return (
        <div ref={plotRef}>
        <VolcanoPlot
          compData={compData}
          filterData={filterData}
          title="Volcano Plot"
        />
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-center w-full h-64">
        <svg className="animate-spin h-10 w-10 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
        <span className="ml-2">Loading Volcano Plot...</span>
      </div>
    );
  }
    } else if (filterData.plot === 'MA plot') {
      return (
        <div ref={plotRef}>
          <Plot
            data={[
              {
                x: XAverageExpression,
                y: YLogFoldChangeUpRegulated,
                mode: 'markers',
                marker: {
                  size: 5,
                  color: '#8C1823',
                },
                text: 'Up regulated', // Hover text for filtered data
                type: 'scatter',
                name: 'Up regulated', // Legend label
              },
              {
                x: XAverageExpression,
                y: YLogFoldChangeDownRegulated,
                mode: 'markers',
                marker: {
                  size: 5,
                  color: '#217867',
                },
                text: 'Down regulated', // Hover text for filtered data
                type: 'scatter',
                name: 'Down regulated', // Legend label
              },
              // Second trace for foldChangeXLogRest and pValueLogYRest
              {
                x: XAverageExpressionRest,
                y: YLogFoldChangeRest,
                mode: 'markers',
                marker: {
                  size: 5,
                  color: 'grey', // Color for rest data
                },
                text: 'Rest', // Hover text for rest data
                type: 'scatter',
                name: 'Rest', // Legend label
              },
            ]}
            layout={{
              width: 1000,
              height: 600,
              title: 'MA Plot',
              xaxis: {
                title: 'A (log2 average)',
              },
              yaxis: {
                title: 'M(log2 foldChange)',
              },
            }}
            config={{
              responsive: true,
              autosizable: true,
              modeBarButtonsToRemove: [
                'zoom2d',
                'pan2d',
                'select2d',
                'lasso2d',
                'zoomIn2d',
                'zoomOut2d',
                'autoScale2d',
                'resetScale2d',
                'hoverClosestCartesian',
                'hoverCompareCartesian',
                'toggleSpikelines',
              ],
              displaylogo: false,
            }}
          />
        </div>
      );
    } else if (filterData.plot === 'Dotplot between groups') {
      return (
        <div ref={plotRef}>
          <Plot
            data={[
              {
                x: XMeanGroup1FilteredUpRegulated,
                y: YMeanGroup2Filtered,
                mode: 'markers',
                marker: {
                  size: 10,
                  color: '#8C1823',
                },
                text: 'Up regulated', // Hover text for filtered data
                type: 'scatter',
                name: 'Up regulated', // Legend label
              },
              {
                x: XMeanGroup1FilteredDownRegulated,
                y: YMeanGroup2Filtered,
                mode: 'markers',
                marker: {
                  size: 10,
                  color: '#217867',
                },
                text: 'Down regulated', // Hover text for filtered data
                type: 'scatter',
                name: 'Down regulated', // Legend label
              },
              // Second trace for foldChangeXLogRest and pValueLogYRest
              {
                x: XMeanGroup1Rest,
                y: YMeanGroup2Rest,
                mode: 'markers',
                marker: {
                  size: 10,
                  color: 'grey', // Color for rest data
                },
                text: 'Rest', // Hover text for rest data
                type: 'scatter',
                name: 'Rest', // Legend label
              },
            ]}
            layout={{
              width: 1000,
              height: 600,
              title: 'Dotplot between groups',
              xaxis: {
                title: `mean_${meanGroup1Name}`,
              },
              yaxis: {
                title: `mean_${meanGroup2Name}`,
              },
            }}
            config={{
              responsive: true,
              autosizable: true,
              modeBarButtonsToRemove: [
                'zoom2d',
                'pan2d',
                'select2d',
                'lasso2d',
                'zoomIn2d',
                'zoomOut2d',
                'autoScale2d',
                'resetScale2d',
                'hoverClosestCartesian',
                'hoverCompareCartesian',
                'toggleSpikelines',
              ],
              displaylogo: false,
            }}
          />
        </div>
      );
    } else if (filterData.plot === 'PCA of differentially expressed') {
      return (
        <div ref={plotRef}>
          <Plot
            data={[
              {
                x: XPCAMean,
                y: YPCAMean,
                type: 'scatter',
                name: groupsName[0],
                mode: 'markers',
                marker: {
                  color: XPCAMean.map((value: number) => {
                    return value !== 0 ? [groupsColor[0]] : [groupsColor[1]];
                  }),
                  size: 10, // Optionally set the size of the markers
                },
              },
              {
                x: XPCAMean,
                y: YPCAMean,
                type: 'scatter',
                name: groupsName[1],
                mode: 'markers',
                marker: {
                  color: YPCAMean.map((value: any, index: any) => {
                    return value !== 0 ? [groupsColor[1]] : [groupsColor[0]];
                  }),
                  size: 10, // Optionally set the size of the markers
                },
              },
            ]}
            layout={{
              width: 1000,
              height: 600,
              title: 'PCA of differentially expressed',
              xaxis: {
                title: 'PC1',
              },
              yaxis: {
                title: 'PC2',
              },
            }}
            config={{
              responsive: true,
              autosizable: true,
              modeBarButtonsToRemove: [
                'zoom2d',
                'pan2d',
                'select2d',
                'lasso2d',
                'zoomIn2d',
                'zoomOut2d',
                'autoScale2d',
                'resetScale2d',
                'hoverClosestCartesian',
                'hoverCompareCartesian',
                'toggleSpikelines',
              ],
              displaylogo: false,
            }}
          />
        </div>
      );
    } else if (filterData.plot === 'Heatmap of differentially expressed') {
      return (
        <div ref={plotRef}>
          <Plot
            data={[
              {
                z: DifferentialExperienceHeatMap,
                type: 'heatmap',
                colorscale: 'Viridis',
              },
            ]}
            layout={{
              title: 'Heatmap',
              height: 600,
              width: 1000,
              showlegend: true,
            }}
            config={{
              responsive: true,
              displayModeBar: true,
              modeBarButtonsToRemove: [
                'zoom2d',
                'pan2d',
                'select2d',
                'lasso2d',
                'zoomIn2d',
                'zoomOut2d',
                'autoScale2d',
                'resetScale2d',
                'hoverClosestCartesian',
                'hoverCompareCartesian',
                'toggleSpikelines',
              ],
              displaylogo: false,
            }}
          />
        </div>
      );
    } else {
      return <h1>Plot type not recognized</h1>;
    }
  };

  return (
    <div>
      <div className="flex items-start gap-6 mb-8">
        <div
          style={{ padding: '16px', maxWidth: '300px' }}
          className="border-r border-b border-dashed w-1/4"
        >
          <Form.Item label="Select comparison" colon={false}>
            <Select
              value={filterData.comparison} // Directly use the string value
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'comparison')} // Pass selected value to the handler
              placeholder="Select an option"
            >
              {comparison.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Title level={5}>DE Criteria</Title>

          <Form.Item label="Fold change threshold" colon={false}>
            <Select
              defaultValue={filterData.fold_change_threshold}
              value={filterData.fold_change_threshold}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'fold_change_threshold')}
            >
              <Option value={1.5}>1.5</Option>
              <Option value={2.0}>2.0</Option>
              <Option value={4.0}>4.0</Option>
              <Option value={null}>None</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Statistical significance threshold" colon={false}>
            <Select
              defaultValue={filterData.statistical_significance_threshold}
              value={filterData.statistical_significance_threshold}
              style={{ width: '100%' }}
              //onChange={handleChange}
              onChange={(value) =>
                handleChange(value, 'statistical_significance_threshold')
              }
            >
              <Option value={0.1}>0.1</Option>
              <Option value={0.05}>0.05</Option>
              <Option value={0.01}>0.01</Option>
              <Option value={null}>None</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Use" colon={false}>
            <Select
              value={filterData.use}
              defaultValue={filterData.use}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'use')}
            >
              <Option value="Pvalue">Pvalue</Option>
              <Option value="FDR">FDR</Option>
            </Select>
          </Form.Item>
          <Title level={5}>Figure</Title>
          <Form.Item label="Plot" colon={false}>
            <Select
              value={filterData.plot}
              defaultValue={filterData.plot}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'plot')}
            >
              <Option value="Volcano">Volcano</Option>
              <Option value="MA plot">MA plot</Option>
              <Option value="Dotplot between groups">
                Dotplot between groups
              </Option>
              <Option value="PCA of differentially expressed">
                PCA of differentially expressed
              </Option>
              <Option value="Heatmap of differentially expressed">
                Heatmap of differentially expressed
              </Option>
            </Select>
          </Form.Item>

          <Form.Item label="Genes in the table" colon={false}>
            <Select
              value={filterData.genes}
              defaultValue={filterData.genes}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'genes')}
            >
              <Option value="All genes">All genes</Option>
              <Option value="Only differentially expressed">
                Only differentially expressed
              </Option>
            </Select>
          </Form.Item>
        </div>

        {/* Plot Section */}
        <div className="flex-1 flex flex-col items-center  p-4">
          <div className="flex justify-between w-full mb-4">
            <h1 className="text-2xl font-semibold mb-5">
              Differential Expression
            </h1>
            <button className="text-nexco-green mr-8" onClick={DownloadImage}>
              Download
            </button>
          </div>
          <div className="text-sm text-gray-500 mb-6 text-left block w-full">
            <h3 className="mb-3 font-semibold text-black">Volcano</h3>
            <p className="w-5/6">
              Volcano plot depicting genes by log2 fold change (X-axis) and
              -log10 p-value (Y-axis). Upregulated and downregulated genes are
              positioned towards the plot extremes
            </p>
          </div>

          {/* Volcano Plot */}
          <div className="flex items-center justify-center w-full">
            {DifferentialExpressionPlots()}
          </div>
        </div>
      </div>
      <h3 className="text-xl font-semibold mb-5">Sample metadata</h3>
      <p>Sample definitions</p>
      <ToastContainer />
      <DataTable
        columns={columns}
        data={searchResults}
        onSearch={handleSearch}
        downloadTableData={downloadTableData}
      />
    </div>
  );
};

export default DifferentialExpression;
