import React, { useState, useMemo } from 'react';
import Plot from 'react-plotly.js';
import Select from 'react-select';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { getDateRange, customStyles } from '../../utils/projects';
// import data from './projects/julien.duc@nexco.ch/YWFh.json'; 
import { tableColumns, renderTableHeader } from './tableOptions';
import { plotConfig, pcaLayout, volcanoLayout } from './plotOptions';

const Vnex2 = () => {
  // State for selected comparison
  const [selectedComparison, setSelectedComparison] = useState(Object.keys(data.DE)[0]);

  // Options for the Select component
  const comparisonOptions = useMemo(
    () =>
      Object.keys(data.DE).map((comp) => ({
        value: comp,
        label: comp,
      })),
    [data.DE]
  );

  // Pagination and Search States
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Sorting State
  const [sorting, setSorting] = useState([]);

  // Filtered Data based on Search Query
  const filteredData = useMemo(() => {
    if (!searchQuery) return Object.values(data.DE[selectedComparison]);

    return Object.values(data.DE[selectedComparison]).filter((row) =>
      tableColumns.some((col) => {
        const value = col.accessorKey
          ? row[col.accessorKey]
          : col.accessorFn(row);
        return value?.toString().toLowerCase().includes(searchQuery.toLowerCase());
      })
    );
  }, [searchQuery, selectedComparison, data.DE, tableColumns]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get Current Page Data
  const currentData = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return filteredData.slice(start, start + itemsPerPage);
  }, [filteredData, currentPage, itemsPerPage]);

  // Handle Search Input Change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Handle Items Per Page Change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on items per page change
  };

  // Handle Page Change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate Page Numbers for Pagination
  const pageNumbers = useMemo(() => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }, [totalPages]);

  // Initialize react-table
  const table = useReactTable({
    data: currentData,
    columns: tableColumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="p-4">
      {/* PCA Plot */}
      <h2 className="text-xl font-semibold mb-4">Example PCA</h2>
      
      {/* Container to Restrict Plot Width */}
      <div className="w-full md:w-1/2 mx-auto mb-8">
        <Plot
          data={[
            {
              x: Object.values(data.pca.x).map((sample) => sample.PC1),
              y: Object.values(data.pca.x).map((sample) => sample.PC2),
              type: 'scatter',
              mode: 'markers',
              marker: { color: 'blue' },
              text: Object.values(data.pca.x).map((sample) => sample._row),
            },
          ]}
          layout={pcaLayout}
          config={plotConfig}
          style={{ width: '100%', height: '400px' }}
        />
      </div>

      {/* Volcano Plot */}
      <h2 className="text-xl font-semibold mt-8 mb-4">Example Volcano</h2>
      <div className="mb-8">
        {/* Left Pane: Selector */}
        <div className="w-full md:w-1/4 pr-0 md:pr-4 mb-4 md:mb-0">
          <label htmlFor="comparison-select" className="block mb-2">
            Select Comparison:
          </label>
          <Select
            id="comparison-select"
            value={comparisonOptions.find((option) => option.value === selectedComparison)}
            onChange={(option) => setSelectedComparison(option.value)}
            options={comparisonOptions}
            styles={customStyles}
          />
        </div>

        {/* Volcano Plot */}
        <div className="w-full">
          <Plot
            data={[
              {
                x: Object.values(data.DE[selectedComparison]).map((sample) =>
                  Math.sign(sample.foldChange) * Math.log2(Math.abs(sample.foldChange))
                ),
                y: Object.values(data.DE[selectedComparison]).map((sample) => -Math.log10(sample.Pval)),
                type: 'scatter',
                mode: 'markers',
                marker: { color: 'green' },
                text: Object.values(data.DE[selectedComparison]).map((sample) => sample.hgnc_symbol),
              },
            ]}
            layout={volcanoLayout(selectedComparison)}
            config={plotConfig}
            style={{ width: '100%', height: '400px' }}
          />
        </div>
      </div>

      {/* DE Results Table with Sorting, Pagination, and Search */}
      <h2 className="text-xl font-semibold mt-8 mb-4">DE Results Table</h2>

      {/* Search and Entries Per Page */}
      <div className="flex flex-col md:flex-row justify-between mb-4">
        {/* Search Bar */}
        <div className="mb-4 md:mb-0">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search..."
            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </div>

        {/* Entries Per Page Selector */}
        <div>
          <label htmlFor="items-per-page" className="mr-2">
            Show
          </label>
          <select
            id="items-per-page"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
          >
            {[10, 20, 30, 50, 100].map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
          <span className="ml-2">entries</span>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer"
                  >
                    {renderTableHeader(header, flexRender)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="hover:bg-gray-50">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="px-6 py-4 border-b border-gray-200 text-sm text-gray-700"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        {/* Entries Info */}
        <div>
          Showing{' '}
          {currentData.length > 0 ? (currentPage - 1) * itemsPerPage + 1 : 0} -{' '}
          {Math.min(currentPage * itemsPerPage, filteredData.length)} of {filteredData.length} entries
        </div>

        {/* Pagination Buttons */}
        <div className="flex space-x-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1 border border-gray-300 rounded-md ${
              currentPage === 1
                ? 'bg-gray-200 cursor-not-allowed'
                : 'bg-white hover:bg-gray-100'
            }`}
          >
            Previous
          </button>

          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => handlePageChange(number)}
              className={`px-3 py-1 border border-gray-300 rounded-md ${
                currentPage === number
                  ? 'bg-teal-500 text-white'
                  : 'bg-white hover:bg-gray-100'
              }`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 border border-gray-300 rounded-md ${
              currentPage === totalPages
                ? 'bg-gray-200 cursor-not-allowed'
                : 'bg-white hover:bg-gray-100'
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Vnex2;