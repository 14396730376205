import React, { useState, useCallback } from 'react';
import { Project } from '../../interface/project';
import { Checkbox } from '@mui/material';
import {
  updateProjectName,
  GetProjectResults,
} from '../../services/projectApi';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiSettings, FiShare2, FiTrash2 } from 'react-icons/fi';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { GrDocumentPerformance } from 'react-icons/gr';
import UploadModal from './upload/index';
interface ProjectRowProps {
  project: Project;
  isSelected: boolean;
  handleSelectProject: (
    name: string,
    isSelected: boolean,
    isShiftKey: boolean,
  ) => void;
  onNameChange: (oldName: string, newName: string) => void;
  handleDeleteProject: (name: string) => void;
  handleShareProject: (name: string) => void;
}

const getStatus = (status: string, projectName: string, projectId?: number) => {
  const navigate = useNavigate();
  const handleConfigRedirect = () => {
    navigate(`/configure/${projectName}`);
  };

  const handleLogRedirect = () => {
    navigate(`/ProgressLog/${projectName}`);
  };

  const handleViewRedirect = async () => {
    if (projectId !== undefined) {
      try {
        const url = await GetProjectResults(projectId);
        if (url) {
          // Open in a new tab using anchor tag for better compatibility
          // const link = document.createElement('a');
          // link.href = url;
          // link.target = '_blank';
          // link.rel = 'noopener noreferrer'; // For security reasons
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);

          //navigating to the view page inside react application...

          navigate(`/view-analysis/${projectName}`);
        }
      } catch (error) {
        console.error('Error fetching project results:', error);
      }
    }
  };

  if (status === 'Ready') {
    return (
      <div
        title="view processed data"
        className="flex items-center space-x-1 text-gray-500 cursor-pointer"
        onClick={status === 'Ready' ? handleViewRedirect : undefined}
      >
        <FiEye className="text-nexco-green" aria-label="View" />
        <span className="pl-0.5 pb-1 font-semibold text-nexco-green">View</span>
      </div>
    );
  } else if (status === 'Configured' || status === 'Failed') {
    return (
      <div
        title="view logs"
        className={`flex items-center space-x-1 cursor-pointer ${
          status === 'Failed' ? 'text-red-500' : 'text-teal-600'
        }`}
        onClick={handleLogRedirect}
      >
        <GrDocumentPerformance aria-label="View" />
        <span className="pl-0.5 font-semibold">
          {status === 'Failed' ? 'Failed' : 'In Progress'}
        </span>
      </div>
    );
  } else if (status === 'Uploaded') {
    return (
      <div
        title="configure project"
        className="flex items-center space-x-1 text-gray-500 cursor-pointer"
        onClick={status === 'Uploaded' ? handleConfigRedirect : undefined}
      >
        <FiSettings aria-label="Module" />
        <span className="pl-0.5 pb-1 font-semibold">Configure</span>
      </div>
    );
  } else {
    return (
      <div
        title="uploading files"
        className="flex items-center space-x-1 text-nexco-orange"
      >
        <AiOutlineCloudUpload />
        <span className="pl-0.5 font-semibold">Uploading</span>
      </div>
    );
  }
};

const ProjectRow: React.FC<ProjectRowProps> = ({
  project,
  isSelected,
  handleSelectProject,
  onNameChange,
  handleDeleteProject,
  handleShareProject,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(project.name);
  const [fileType, setFileType] = useState<string>(project.fileType);
  const navigate = useNavigate();

  const handleConfigRedirect = () => {
    navigate(`/configure/${project.name}`);
  };

  const handleLogRedirect = () => {
    navigate(`/ProgressLog/${project.name}`);
  };

  const handleViewRedirect = async () => {
    try {
      const url = await GetProjectResults(project.id);
      if (url) {
        // Open in a new tab using anchor tag for better compatibility
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.rel = 'noopener noreferrer'; // For security reasons
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error fetching project results:', error);
    }
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(e.target.value);
  };

  const saveProjectName = async () => {
    if (editedName !== project.name) {
      try {
        await updateProjectName(project.name, editedName);
        onNameChange(project.name, editedName);
      } catch (error) {
        // Handle error (e.g., show a notification to the user)
      }
    }
  };

  const handleBlur = async () => {
    setIsEditing(false);
    await saveProjectName();
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      await saveProjectName();
    }
  };

  const isUploaded = project.status === 'Uploaded';
  const isConfigured = project.status === 'Configured';
  const isFailed = project.status === 'Failed';
  const canView = project.status === 'Ready';
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!isUploadModalOpen);
  };
  return (
    <tr className="hover:bg-gray-100">
      <td className="relative items-center w-1/5 py-4 text-gray-500 border-b">
        <div className="relative flex items-center group">
          <Checkbox
            className="form-checkbox"
            checked={isSelected}
            onChange={(e) =>
              handleSelectProject(
                project.name,
                e.target.checked,
                (e.nativeEvent as MouseEvent).shiftKey,
              )
            }
          />
          {isEditing ? (
            <input
              type="text"
              value={editedName}
              onChange={handleNameChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              autoFocus
              className="px-2 py-1 ml-3 border border-gray-300 rounded"
            />
          ) : (
            <span
              className="max-w-xs ml-3 truncate cursor-pointer"
              onDoubleClick={handleDoubleClick}
            >
              {project.name}
            </span>
          )}
          {/* Tooltip */}
          <div className="absolute left-0 p-2 mb-2 text-gray-900 transition-opacity duration-300 bg-white border border-gray-300 rounded shadow-lg opacity-0 bottom-full group-hover:opacity-100 whitespace-nowrap">
            {project.name}
          </div>
        </div>
      </td>

      <td className="w-1/5 px-6 py-4 text-gray-500 border-b">{project.type}</td>
      <td className="w-1/5 px-6 py-4 text-gray-500 border-b">
        {project.genome}
      </td>
      <td className="w-1/5 px-6 py-4 text-gray-500 border-b">
        {project.dateAdded}
      </td>
      <td className="w-1/5 px-6 py-4 text-gray-500 border-b">
        {getStatus(project.status, project.name, project.id)}
      </td>
      <td className="w-1/12 px-6 py-4 text-right border-b">
        <div className="flex justify-end space-x-2">
          <FiEye
            title="view processed data"
            className={`${canView ? 'cursor-pointer text-gray-600' : ' cursor-not-allowed text-gray-400'}`}
            aria-label="View"
            onClick={canView ? handleViewRedirect : undefined}
          />
          <FiSettings
            title="configure project"
            className={`${isUploaded || isConfigured || isFailed || !canView ? 'cursor-pointer text-gray-600' : ' cursor-not-allowed text-gray-400'}`}
            aria-label="Module"
            onClick={
              isUploaded || isConfigured || isFailed || !canView
                ? handleConfigRedirect
                : undefined
            }
          />
          <AiOutlineCloudUpload
            onClick={
              (fileType == 'fastq.gz' || fileType == 'fq.gz') && !canView
                ? toggleUploadModal
                : undefined
            }
            className={`${(fileType == 'fastq.gz' || fileType == 'fq.gz') && !canView ? 'cursor-pointer text-gray-600' : ' cursor-not-allowed text-gray-400'}`}
            aria-label="Upload"
          >
            Upload Files
          </AiOutlineCloudUpload>

          <FiShare2
            title="share project"
            className={`${canView ? 'text-gray-600 cursor-pointer' : 'text-gray-400 cursor-not-allowed'}`}
            aria-label="Share"
            onClick={
              canView ? () => handleShareProject(project.name) : undefined
            }
          />

          <GrDocumentPerformance
            title="view logs"
            className={`${isConfigured || isFailed || canView ? 'cursor-pointer text-gray-600' : 'text-gray-400  cursor-not-allowed'}`}
            aria-label="Log"
            onClick={
              isConfigured || isFailed || canView
                ? handleLogRedirect
                : undefined
            }
          />
          <FiTrash2
            title="delete project"
            className="text-orange-600 cursor-pointer"
            aria-label="Delete"
            onClick={() => handleDeleteProject(project.name)}
          />
        </div>
      </td>
      {isUploadModalOpen && (
        <UploadModal
          isOpen={isUploadModalOpen}
          onClose={toggleUploadModal}
          projectName={project.name}
          experiment={project.type}
        />
      )}
    </tr>
  );
};

export default ProjectRow;
