import { Form, Select, Typography } from 'antd';
const { Option } = Select;
const { Title } = Typography;
import React, { useEffect, useRef, useState } from 'react';
import { useProfile } from '../../contexts/ProfileContext';
import Plot from 'react-plotly.js';
import { toast, ToastContainer } from 'react-toastify';
import {
  Comparison,
  GetDifferentialExpression,
} from '../../services/vnexPlotApi';
import DataTable from './DataTable';
import { IBasicAnalysis } from '../../data/basicAnalysis';
import { ColumnsType } from 'antd/es/table';

interface DifferentialExpressionProps {
  projectName: string;
}
export interface IfilterDataDifferential {
  comparison: string[];
  fold_change_threshold: number;
  statistical_significance_threshold: number;
  use: string;
  plot: string;
  genes: string;
}

const QualityControl: React.FC<DifferentialExpressionProps> = ({
  projectName,
}) => {
  const { profile } = useProfile();
  const email = profile ? profile.email : '';

  const plotRef = useRef<any>();
  const initialFilterData = {
    fold_change_threshold: 4.0,
    statistical_significance_threshold: 0.1,
    use: 'Pvalue',
    plot: 'Volcano',
    genes: 'All genes',
  };

  const QualityControlPlots1 = () => {
    return (
      <div ref={plotRef}>
        <Plot
          data={[
            {
              x: [1.5, 2.1, 2.8, 3.0, 3.5],
              y: [-1.5, -2.1, -2.8, -3.0, -3.5],
              mode: 'markers',
              marker: {
                size: 10,
                color: '#8C1823',
              },
              text: 'Up regulated', // Hover text for filtered data
              type: 'scatter',
              name: 'Up regulated',
              // Legend label
            },
          ]}
          layout={{
            width: 1000,
            height: 600,
            title: 'Volcano Plot',
            showlegend: true,

            xaxis: {
              title: 'FoldChange (log2)',
            },
            yaxis: {
              title: 'P-value (-log 10)',
            },
          }}
          config={{
            responsive: true,
            autosizable: true,
            modeBarButtonsToRemove: [
              'zoom2d',
              'pan2d',
              'select2d',
              'lasso2d',
              'zoomIn2d',
              'zoomOut2d',
              'autoScale2d',
              'resetScale2d',
              'hoverClosestCartesian',
              'hoverCompareCartesian',
              'toggleSpikelines',
            ],
            displaylogo: false,
          }}
        />
      </div>
    );
  };
  const QualityControlPlots2 = () => {
    return (
      <div ref={plotRef}>
        <Plot
          data={[
            {
              x: [1.5, 2.1, 2.8, 3.0, 3.5],
              y: [-1.5, -2.1, -2.8, -3.0, -3.5],
              mode: 'markers',
              marker: {
                size: 10,
                color: '#8C1823',
              },
              type: 'scatter',
              name: 'Up regulated',
            },
          ]}
          layout={{
            width: 1000,
            height: 600,
            title: 'Volcano Plot',
            showlegend: true,

            xaxis: {
              title: 'FoldChange (log2)',
            },
            yaxis: {
              title: 'P-value (-log 10)',
            },
          }}
          config={{
            responsive: true,
            autosizable: true,
            modeBarButtonsToRemove: [
              'zoom2d',
              'pan2d',
              'select2d',
              'lasso2d',
              'zoomIn2d',
              'zoomOut2d',
              'autoScale2d',
              'resetScale2d',
              'hoverClosestCartesian',
              'hoverCompareCartesian',
              'toggleSpikelines',
            ],
            displaylogo: false,
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="flex items-start gap-6 mb-8">
        {/* <div
          style={{ padding: '16px', maxWidth: '300px' }}
          className="border-r border-b border-dashed w-1/4"
        >
          <Form.Item label="Select comparison" colon={false}>
            <Select
              value={filterData.comparison} // Directly use the string value
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'comparison')} // Pass selected value to the handler
              placeholder="Select an option"
            >
              {comparison.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Title level={5}>DE Criteria</Title>

          <Form.Item label="Fold change threshold" colon={false}>
            <Select
              defaultValue={filterData.fold_change_threshold}
              value={filterData.fold_change_threshold}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'fold_change_threshold')}
            >
              <Option value={1.5}>1.5</Option>
              <Option value={2.0}>2.0</Option>
              <Option value={4.0}>4.0</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Statistical significance threshold" colon={false}>
            <Select
              defaultValue={filterData.statistical_significance_threshold}
              value={filterData.statistical_significance_threshold}
              style={{ width: '100%' }}
              //onChange={handleChange}
              onChange={(value) =>
                handleChange(value, 'statistical_significance_threshold')
              }
            >
              <Option value={0.1}>0.1</Option>
              <Option value={0.05}>0.05</Option>
              <Option value={0.01}>0.01</Option>
              <Option value="None">None</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Use" colon={false}>
            <Select
              value={filterData.use}
              defaultValue={filterData.use}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'use')}
            >
              <Option value="Pvalue">Pvalue</Option>
              <Option value="FDR">FDR</Option>
            </Select>
          </Form.Item>
          <Title level={5}>Figure</Title>
          <Form.Item label="Plot" colon={false}>
            <Select
              value={filterData.plot}
              defaultValue={filterData.plot}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'plot')}
            >
              <Option value="Volcano">Volcano</Option>
              <Option value="MA plot">MA plot</Option>
              <Option value="Dotplot between groups">
                Dotplot between groups
              </Option>
              <Option value="PCA of differentially expressed">
                PCA of differentially expressed
              </Option>
              <Option value="Heatmap of differentially expressed">
                Heatmap of differentially expressed
              </Option>
            </Select>
          </Form.Item>

          <Form.Item label="Genes in the table" colon={false}>
            <Select
              value={filterData.genes}
              defaultValue={filterData.genes}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'genes')}
            >
              <Option value="All genes">All genes</Option>
              <Option value="Only differentially expressed">
                Only differentially expressed
              </Option>
            </Select>
          </Form.Item>
        </div> */}

        {/* Plot Section */}
        <div className="flex-1 flex flex-col items-center  p-4">
          <div className="flex justify-between w-full mb-4">
            <h1 className="text-2xl font-semibold mb-5">Quality Control</h1>
            {/* <button className="text-nexco-green mr-8" onClick={DownloadImage}>
              Download
            </button> */}
          </div>
          {/* <div className="text-sm text-gray-500 mb-6 text-left block w-full">
            <h3 className="mb-3 font-semibold text-black">Volcano</h3>
            <p className="w-5/6">
              Volcano plot depicting genes by log2 fold change (X-axis) and
              -log10 p-value (Y-axis). Upregulated and downregulated genes are
              positioned towards the plot extremes
            </p>
          </div> */}

          {/* Volcano Plot */}
          <div>{QualityControlPlots1()}</div>
        </div>
      </div>
      <h3 className="text-xl font-semibold mb-5">Sample metadata</h3>
      <p>Sample definitions</p>
      {QualityControlPlots2()}
      <ToastContainer />
      {/* <DataTable
        columns={columns}
        data={searchResults}
        onSearch={handleSearch}
        downloadTableData={downloadTableData}
      /> */}
    </div>
  );
};

export default QualityControl;
