import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';

interface CompData {
  ensembl_gene_id: string[];
  entrezgene_id: string[];
  hgnc_symbol: string[];
  description: string[];
  'mean.G1': number[];
  'mean.G2': number[];
  foldChange: number[];
  Pval: number[];
  FDR: number[];
  diffExp: string[];
}

interface IfilterDataDifferential {
  comparison: string[];
  fold_change_threshold: number | null;
  statistical_significance_threshold: number | null;
  use: string; // 'Pval' or 'FDR'
  plot: string;
  genes: string;
}

interface VolcanoPlotProps {
  compData: CompData;
  filterData: IfilterDataDifferential;
  title?: string;
}

const VolcanoPlot: React.FC<VolcanoPlotProps> = ({ compData, filterData, title = 'Volcano Plot' }) => {
  // Destructure necessary data from compData
  const {
    foldChange,
    Pval,
    FDR,
    hgnc_symbol,
  } = compData;

  // Determine which p-value to use based on filterData.use
  const selectedPval = filterData.use === 'FDR' ? FDR : Pval;

  // Define thresholds, providing default values if null
  const foldChangeThreshold = filterData.fold_change_threshold ?? 2.0;
  const pvalThreshold = filterData.statistical_significance_threshold ?? 0.05;

  const cachedValues = useMemo(() => {
    const log2FC: number[] = [];
    const logPval_Pval: number[] = [];
    const logPval_FDR: number[] = [];
  
    foldChange.forEach((fc, index) => {
      // Compute log2 Fold Change
      const log2FCCalculated = fc !== 0 ? Math.sign(fc) * Math.log2(Math.abs(fc)) : 0;
      log2FC.push(log2FCCalculated);
  
      // Compute -log10(Pval) and -log10(FDR)
      const pval = Pval[index];
      const fdr = FDR[index];
  
      const logPval = pval > 0 ? -Math.log10(pval) : 8;
      const logFDR = fdr > 0 ? -Math.log10(fdr) : 8;
  
      logPval_Pval.push(logPval);
      logPval_FDR.push(logFDR);
    });
  
    return { log2FC, logPval_Pval, logPval_FDR };
  }, [foldChange, Pval, FDR]);

  const selectedLogPval = filterData.use === 'FDR' ? cachedValues.logPval_FDR : cachedValues.logPval_Pval;


  // Memoize the computation for performance optimization
  const plotData = useMemo(() => {
    const upRegulated = {
      x: [] as number[],
      y: [] as number[],
      text: [] as string[],
      mode: 'markers' as const,
      marker: { size: 8, color: 'red' },
      type: 'scatter' as const,
      name: 'Upregulated',
    };

    const downRegulated = {
      x: [] as number[],
      y: [] as number[],
      text: [] as string[],
      mode: 'markers' as const,
      marker: { size: 8, color: 'green' },
      type: 'scatter' as const,
      name: 'Downregulated',
    };

    const notSignificant = {
      x: [] as number[],
      y: [] as number[],
      text: [] as string[],
      mode: 'markers' as const,
      marker: { size: 8, color: 'BBBBBB60' },
      type: 'scatter' as const,
      name: 'Not Significant',
    };

    cachedValues.log2FC.forEach((logfc, index) => {
      const pval = selectedPval[index];
      const geneSymbol = hgnc_symbol[index] || 'N/A';
      const fc = foldChange[index];
  
      if (isNaN(pval)) return;
  
      const logPval = selectedLogPval[index] || 0;
  
      if (Math.abs(fc) >= foldChangeThreshold && pval <= pvalThreshold) {
        if (fc > 0) {
          // Upregulated
          upRegulated.x!.push(logfc);
          upRegulated.y!.push(logPval);
          upRegulated.text!.push(geneSymbol);
        } else if (fc < 0) {
          // Downregulated
          downRegulated.x!.push(logfc);
          downRegulated.y!.push(logPval);
          downRegulated.text!.push(geneSymbol);
        }
      } else {
        // Not significant
        notSignificant.x!.push(logfc);
        notSignificant.y!.push(logPval);
        notSignificant.text!.push(geneSymbol);
      }
    });

    return [notSignificant, upRegulated, downRegulated] as Plotly.Data[];
  }, [
    cachedValues.log2FC,
    selectedLogPval,
    hgnc_symbol,
    foldChangeThreshold,
    pvalThreshold,
  ]);

  return (
    <div>
      <Plot
        data={plotData}
        layout={{
          width: 800,
          height: 600,
          title: title,
          showlegend: true,
          xaxis: {
            title: 'Fold Change (log2)',
          },
          yaxis: {
            title: '-Log10 P-value',
          },
          hovermode: 'closest',
        }}
        config={{
          responsive: true,
          autosizable: true,
          modeBarButtonsToRemove: [
            'zoom2d',
            'pan2d',
            'select2d',
            'lasso2d',
            'zoomIn2d',
            'zoomOut2d',
            'autoScale2d',
            'resetScale2d',
            'hoverClosestCartesian',
            'hoverCompareCartesian',
            'toggleSpikelines',
          ],
          displaylogo: false,
        }}
      />
    </div>
  );
};

export default VolcanoPlot;
    