import { toast } from 'react-toastify';
import { IfilterData } from '../components/AnalysisPlots/Clustering';
import { apiClient } from './apiClient';
import { IfilterDataDifferential } from '../components/AnalysisPlots/BasicAnalysis/DifferentialExpression';
import { IfilterDataListOverlap } from '../components/AnalysisPlots/BasicAnalysis/ListOverlap';

// Descriptive Screen API
export const GetDescriptiveInfo = async (
  projectName: string,
  email: string,
): Promise<any> => {
  try {
    return apiClient.get(`/project/${projectName}/descriptive-info`, {
      params: {
        current_user: email,
      },
    });
  } catch (error: any) {
    console.error('Error fetching sample info:', error);
    const errorMessage =
      error.response?.data?.message || 'An error occurred while fetching data.';
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Clustering Screen Get Groups
export const ProjectGroups = async (
  projectName: string,
  email: string,
): Promise<any> => {
  try {
    return await apiClient.get(`/project-groups`, {
      params: {
        project_name: projectName, // Include the project name
        current_user: email, // Include the current user email
      },
    });
  } catch (error: any) {
    console.error('Error fetching sample info:', error);
    const errorMessage =
      error.response?.data?.message || 'An error occurred while fetching data.';
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Clustering Screen API
export const Clustring = async (
  filterData: IfilterData,
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.post(`/clustering/${project_name}`, {
      plot: filterData.plot,
      current_user: email,
      dimensions: filterData.dimensions,
      num_genes: filterData.num_genes,
      row_metric: filterData.row_metric,
      col_metric: filterData.col_metric,
      group_names: filterData.selectedGroups,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Basic Analysis > DifferentialExpression Screen

export const Comparison = async (
  projectName: string,
  email: string,
): Promise<any> => {
  try {
    return await apiClient.get(`/meta-comparison`, {
      params: {
        project_name: projectName, // Include the project name
        current_user: email, // Include the current user email
      },
    });
  } catch (error: any) {
    console.error('Error fetching sample info:', error);
    const errorMessage =
      error.response?.data?.message || 'An error occurred while fetching data.';
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const GetDifferentialExpression = async (
  filterData: IfilterDataDifferential,
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.post(`/differencial-expression/${project_name}`, {
      comparison: filterData.comparison,
      current_user: email,
      fold_change_threshold: filterData.fold_change_threshold,
      statistical_significance_threshold:
        filterData.statistical_significance_threshold,
      use: filterData.use,
      plot: filterData.plot,
      genes_in_table: filterData.genes,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Basic Analysis > List Overlap Screen

export const ListOverlapAPI = async (
  filterData: IfilterDataListOverlap,
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.post(`/prepare-upset-data/${project_name}`, {
      current_user: email,
      fold_change_threshold: filterData.fold_change_threshold,
      statistical_significance_threshold:
        filterData.statistical_significance_threshold,
      use: filterData.use,
      selected_comparisons: filterData.selectedGroups,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};
