export const plotConfig = {
  displayModeBar: true,
//   modeBarButtons: [['toImage']], // Only show the "Download as PNG" button
  displaylogo: false, // Hides the Plotly logo
  responsive: true,
  scrollZoom: true, // Enable zooming with the mouse wheel
};

export const pcaLayout = {
  title: 'PCA Plot: PC1 vs PC2',
  paper_bgcolor: 'rgba(0, 0, 0, 0)', // Transparent background
  plot_bgcolor: 'rgba(0, 0, 0, 0)',  // Transparent background
  dragmode: 'pan', // Enable panning with left click drag
};

export const volcanoLayout = (comparison) => ({
  title: `Volcano Plot: ${comparison}`,
  xaxis: { title: 'Log2 Fold Change' },
  yaxis: { title: '-Log10 P Value' },
  paper_bgcolor: 'rgba(0, 0, 0, 0)', // Transparent background
  plot_bgcolor: 'rgba(0, 0, 0, 0)',  // Transparent background
  dragmode: 'pan', // Enable panning with left click drag
});