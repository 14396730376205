import { Checkbox, Select, Divider, Typography, Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useProfile } from '../../../contexts/ProfileContext';
import { ListOverlapAPI, Comparison } from '../../../services/vnexPlotApi';
import { toast, ToastContainer } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
const { Option } = Select;
const { Title } = Typography;
import 'react-toastify/dist/ReactToastify.css';
import Plotly from 'plotly.js-basic-dist';

interface ListOverlapProps {
  projectName: string;
}
export interface IfilterDataListOverlap {
  fold_change_threshold: number | null;
  statistical_significance_threshold: number | null;
  use: string;
  selectedGroups: string[];
}

const ListOverlap: React.FC<ListOverlapProps> = ({ projectName }) => {
  const [groups, setGroups] = useState<string[]>([]);
  const { profile } = useProfile();
  const plotRef = useRef<any>();

  const email = profile ? profile.email : '';

  const initialFilterData = {
    plot: 'PCA',
    fold_change_threshold: 4.0,
    statistical_significance_threshold: 0.1,
    use: 'Pvalue',
    selectedGroups: groups,
  };

  const [filterData, setFilterData] =
    useState<IfilterDataListOverlap>(initialFilterData);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<any>();

  const handleChange = (value: string | string[] | number, name: string) => {
    if (
      typeof (value === 'string' || value === 'object' || value === null) &&
      name
    ) {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const listOverlapRequest = async () => {
      try {
        setLoading(true);
        const response = await ListOverlapAPI(filterData, email, projectName);
        setData(response.data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);

        const errorMessage =
          error?.response?.data?.detail ||
          'An error occurred while fetching data.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };
    listOverlapRequest();

    // if (filterData.selectedGroups.length > 0) {
    //   listOverlapRequest();
    // }
  }, [filterData]);

  useEffect(() => {
    const GroupsData = async () => {
      try {
        setLoading(true);
        const response = await Comparison(projectName, email);

        setGroups(response.data);
        setFilterData((prev) => ({
          ...prev,
          ['selectedGroups']: response.data,
        }));
        setLoading(false);
      } catch (error: any) {
        setLoading(false);

        const errorMessage =
          error?.response?.data?.detail ||
          'An error occurred while fetching data.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };
    GroupsData();
  }, []);
  console.log('setGroups', groups);
  console.log('binary_matrix1', groups.length);

  const DownloadImage = async () => {
    const modebarButton = plotRef.current.querySelector(
      '.modebar-btn[data-title="Download plot as a png"]',
    );
    if (modebarButton) {
      modebarButton.click();
      toast.success('Image downloaded successfully!', {
        position: 'top-right',
      });
    } else {
      const errorMessage = 'Plot reference is not available.';
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  const binary_matrix = data?.binary_matrix;

  // const binary_matrix1 = groups?.map((item: any, index: any) => {
  //   console.log(`Index: ${index}, Value: ${item}`);
  // });
  const UpSetPlot: React.FC = () => {
    useEffect(() => {
      // const exampleDF = [
      //   { A: 1, B: 1, C: 1 },
      //   { A: 1, B: 1, C: 0 },
      //   { A: 0, B: 1, C: 1 },
      //   { A: 1, B: 1, C: 1 },
      // ];

      const exampleDF = binary_matrix;
      console.log('exampleDF', exampleDF);

      const combinations = (array: string[], size: number): string[][] => {
        if (size === 0) return [[]];
        if (array.length === 0) return [];
        const [first, ...rest] = array;
        const combsWithoutFirst = combinations(rest, size);
        console.log('combsWithoutFirst', combsWithoutFirst);
        const combsWithFirst = combinations(rest, size - 1).map((comb) => [
          first,
          ...comb,
        ]);
        console.log('combsWithFirst', combsWithFirst);
        return [...combsWithFirst, ...combsWithoutFirst];
      };

      // const columns = Object.keys(exampleDF[0]);
      const columns = exampleDF?.length > 0 ? Object.keys(exampleDF[0]) : [];
      if (columns.length === 0) {
        console.error('No columns found in the dataset.');
        return;
      }
      console.log('columns', columns);
      const d = columns.length;
      // console.log('d', d);
      let subsets: string[][] = [];

      for (let i = 1; i <= d; i++) {
        subsets = subsets.concat(combinations(columns, i));
      }

      const subsetSizes = subsets.map(
        (subset) =>
          exampleDF.filter((row: any) =>
            columns.every((col: any) =>
              subset.includes(col) ? row[col] : !row[col],
            ),
          ).length,
      );

      const sortedData = subsets
        .map((subset, i) => ({
          subset,
          size: subsetSizes[i],
        }))
        .sort((a, b) => b.size - a.size);

      const sortedSubsets = sortedData.map((d) => d.subset);
      const sortedSizes = sortedData.map((d) => d.size);
      const maxY = Math.max(...sortedSizes) * 1.1;

      const scatterX: number[] = [];
      const scatterY: number[] = [];
      sortedSubsets.forEach((subset, i) => {
        subset.forEach((_, j) => {
          scatterX.push(i);
          scatterY.push(-j * (maxY / d) - 0.1 * maxY);
        });
      });

      const trace1: any = {
        x: Array.from({ length: sortedSizes.length }, (_, i) => i),
        y: sortedSizes,
        type: 'bar',
        name: 'Subset Sizes',
        marker: { color: 'grey' },
      };

      const trace2 = {
        x: scatterX,
        y: scatterY,
        mode: 'markers',
        name: 'Intersections',
        marker: { color: 'green', size: 15 },
        line: { color: 'green', width: 2 }, // Line configuration
      };

      const layout = {
        title: 'Upset',
        xaxis: { title: 'Subsets' },
        yaxis: { title: 'Size / Intersection' },
        showlegend: true,
      };
      const config = {
        responsive: true,
        autosizable: true,
        modeBarButtonsToRemove: [
          'zoom2d',
          'pan2d',
          'select2d',
          'lasso2d',
          'zoomIn2d',
          'zoomOut2d',
          'autoScale2d',
          'resetScale2d',
          'hoverClosestCartesian',
          'hoverCompareCartesian',
          'toggleSpikelines',
        ] as Partial<Plotly.ModeBarDefaultButtons>[],
        displaylogo: false,
      };

      Plotly.newPlot('upset-plot', [trace1, trace2], layout, config);
    }, []);

    return <div id="upset-plot"></div>;
  };

  return (
    <div className="flex justify-between">
      <div
        className="border-r-2 border-dashed w-1/4"
        style={{ padding: '16px' }}
      >
        <Title level={5}>Filter</Title>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <ClipLoader color="#36d7b7" size={50} /> {/* Loader component */}
          </div>
        ) : (
          <Form.Item label="Select groups (at least two):">
            <Checkbox.Group
              value={filterData.selectedGroups} // This binds to the state
              onChange={(checkedValues) =>
                handleChange(checkedValues, 'selectedGroups')
              }
            >
              {groups.map((group) => (
                <Checkbox key={group} value={group}>
                  {group}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}
        <Title level={5}>DE Criteria</Title>

        <Form.Item label="Fold change threshold" colon={false}>
          <Select
            defaultValue={filterData.fold_change_threshold}
            value={filterData.fold_change_threshold}
            style={{ width: '100%' }}
            onChange={(value) => handleChange(value, 'fold_change_threshold')}
          >
            <Option value={1.5}>1.5</Option>
            <Option value={2.0}>2.0</Option>
            <Option value={4.0}>4.0</Option>
            <Option value={null}>None</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Statistical significance threshold" colon={false}>
          <Select
            defaultValue={filterData.statistical_significance_threshold}
            value={filterData.statistical_significance_threshold}
            style={{ width: '100%' }}
            //onChange={handleChange}
            onChange={(value) =>
              handleChange(value, 'statistical_significance_threshold')
            }
          >
            <Option value={0.1}>0.1</Option>
            <Option value={0.05}>0.05</Option>
            <Option value={0.01}>0.01</Option>
            <Option value={null}>None</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Use" colon={false}>
          <Select
            value={filterData.use}
            defaultValue={filterData.use}
            style={{ width: '100%' }}
            onChange={(value) => handleChange(value, 'use')}
          >
            <Option value="Pvalue">Pvalue</Option>
            <Option value="FDR">FDR</Option>
          </Select>
        </Form.Item>
      </div>

      <div className="w-3/4 pl-20">
        <div className="flex justify-between ">
          <h1 className="text-2xl font-semibold mb-5">List Overlap</h1>

          <button
            className="text-nexco-green mr-8"
            onClick={() => DownloadImage()}
          >
            Download
          </button>
        </div>
        <ToastContainer />

        <div className="mt-6" ref={plotRef}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <ClipLoader color="#36d7b7" size={50} /> {/* Loader component */}
            </div>
          ) : (
            <div id="upset-plot">
              <div className="mt-6">
                <UpSetPlot />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListOverlap;
