import React, { useState, useMemo, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import Descriptive from '../../components/AnalysisPlots/Descriptive';
import QualityControl from '../../components/AnalysisPlots/QualityControl';
import Clustering from '../../components/AnalysisPlots/Clustering';
import AdvancedAnalysis from '../../components/AnalysisPlots/AdvancedAnalysis';
import GnomeBrowser from '../../components/AnalysisPlots/GnomeBrowser';
import { ConfigProvider } from 'antd';
import { TabButtons } from './TabButtons';
import BasicAnalysis from '../../components/AnalysisPlots/BasicAnalysis';

const analysisTabs = [
  'Descriptive',
  'Quality Control',
  'Clustering',
  'Basic Analysis',
  'Advanced Analysis',
  'Genome Browser',
];

interface HeaderProps {
  projectname: string | undefined;
  navigate: (path: string) => void;
}
type TabName = (typeof analysisTabs)[number];

const Header: React.FC<HeaderProps> = ({ projectname, navigate }) => (
  <h1
    onClick={() => navigate('/')}
    className="text-2xl font-semibold cursor-pointer"
  >
    <ArrowBackIcon className="mb-1 mr-2" />
    {projectname}
  </h1>
);

const ViewAnalysisData = () => {
  const navigate = useNavigate();
  const { projectname } = useParams<{ projectname: string }>();

  const componentMap: Record<TabName, JSX.Element> = useMemo(
    () => ({
      Descriptive: <Descriptive projectName={projectname || ''} />,
      'Quality Control': <QualityControl projectName={projectname || ''} />,
      Clustering: <Clustering projectName={projectname || ''} />,
      'Basic Analysis': <BasicAnalysis />,
      'Advanced Analysis': <AdvancedAnalysis />,
      'Genome Browser': <GnomeBrowser />,
    }),
    [],
  );
  const [selectedTab, setSelectedTab] = useState<TabName>('Descriptive');

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#217867', // your color
        },
        components: {},
      }}
    >
      <div className="container mx-auto">
        <div className="p-4 bg-white rounded-lg shadow-lg shadow-gray-500/50">
          <Header projectname={projectname} navigate={navigate} />
          <TabButtons
            analysisTabs={analysisTabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isChild={false}
          />
          <div className="mt-10">{componentMap[selectedTab]}</div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default ViewAnalysisData;
