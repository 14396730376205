interface TabButtonsProps {
  analysisTabs: string[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  isChild?: boolean;
}

export const TabButtons: React.FC<TabButtonsProps> = ({
  analysisTabs,
  selectedTab,
  setSelectedTab,
  isChild = false,
}) => (
  <div className="flex gap-4 mb-4 text-sm font-medium text-gray-800 mt-10">
    {analysisTabs.map((tab, index) => (
      <button
        key={index}
        onClick={() => setSelectedTab(tab)}
        className={`py-2 px-4 rounded border border-gray-300 ${
          isChild
            ? tab===selectedTab ? 'bg-nexco-green text-white' :'bg-nexco-lgreen'
            : tab === selectedTab 
              ? 'bg-nexco-green text-white'
              : 'bg-white hover:bg-gray-100'
        }`}
      >
        {tab}
      </button>
    ))}
  </div>
);
