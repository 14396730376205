import { flexRender } from '@tanstack/react-table';

export const tableColumns = [
  {
    header: 'Ensembl Gene ID',
    accessorKey: 'ensembl_gene_id',
  },
  {
    header: 'Entrez Gene ID',
    accessorKey: 'entrezgene_id',
  },
  {
    header: 'HGNC Symbol',
    accessorKey: 'hgnc_symbol',
  },
  {
    header: 'Description',
    accessorKey: 'description',
  },
  {
    header: 'Mean O',
    accessorFn: (row) => row['mean.o'] ?? '',
  },
  {
    header: 'Mean G',
    accessorFn: (row) => row['mean.g'] ?? '',
  },
  {
    header: 'Fold Change',
    accessorKey: 'foldChange',
  },
  {
    header: 'P Value',
    accessorKey: 'Pval',
  },
  {
    header: 'FDR',
    accessorKey: 'FDR',
  },
];

// Optional: Export a function to render table headers if customization is needed
export const renderTableHeader = (header, flexRender) => (
  <div
    {...{
      onClick: header.column.getToggleSortingHandler(),
      style: { cursor: header.column.getCanSort() ? 'pointer' : undefined },
    }}
    className="flex items-center"
  >
    {flexRender(header.column.columnDef.header, header.getContext())}
    {header.column.getIsSorted() ? (
      header.column.getIsSorted() === 'asc' ? (
        <span className="ml-2">🔼</span>
      ) : (
        <span className="ml-2">🔽</span>
      )
    ) : null}
  </div>
);