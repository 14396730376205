import { Table, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface SampleDataTableProps<T> {
  columns: ColumnsType<T>;
  data: T[];
  onSearch: (value: string) => void; // Add search handler prop
  downloadTableData: (data: T[], format: string) => void; // Download handler
}

const DataTable = <T extends object>({
  columns,
  data,
  onSearch,
  downloadTableData,
}: SampleDataTableProps<T>) => {
  // Handle download button click

  return (
    <div className="mt-10">
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-xl font-bold">Data Table</h2>
        <div className="flex justify-between gap-4 items-center">
          <button
            onClick={() => downloadTableData(data, 'csv')}
            className={`py-2 px-4 rounded border border-gray-300 bg-nexco-green text-white`}
          >
            Downloads
          </button>
          <Input.Search
            placeholder="Search keyword here"
            allowClear
            // onSearch={(value) => console.log(value)}
            onSearch={onSearch} // Use the search handler passed as a prop
            // onChange={onSearch}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination={{
          pageSize: 5,
          showSizeChanger: false,
          showTotal: (total, range) =>
            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
        }}
        rowKey="key"
      />
    </div>
  );
};

export default DataTable;
